/*Global Style Variables*/
:root {
    --font-montserrat: "Montserrat";
    --accent-color: #ad128d; /*Color accent texts and small buttons (Max, TP/SL)*/
    --accent-color-dark: #8b0e72;
    --text-on-accent: var(--white-color); /*Color button texts and texts on --accent-color*/
    --btn-bg-color: transparent;
    --btn-bg-image: linear-gradient(190deg, #5155BC 0%, #B63B78 100%);
    --btn-bg-dis: #ce9dc4;

    --background-primary-main: #1c1c2d;
    --background-primary-light: transparent;
    --background-primary-dark: #0d042a;
    --background-primary-gradient: transparent;

    --background-secondary: #323245;
    --background-green-gradient: transparent linear-gradient(180deg,#9dffc6,#036c18) 0% 0% no-repeat padding-box;
    --green-border: #01d96d;
    --box-shadow: 0px 3px 6px #00000029;

    --help-bg: var(--background-primary-main);
    --token-box-bg: #CADAF8;
    --selector-bg: #E7EDF8;
    --top-head-bg: transparent linear-gradient(180deg, #002f18 0%, #039160 100%) 0% 0% no-repeat padding-box;
    --modal-bg: rgba(0,0,0,.5);

    --text-color-main: #e9ecf7;
    --text-color-secondary: #e9ecf7;
    --positive-growth: #05ff82;
    --negative-growth: #ff0505;

    --white-color: white;
    --black-color: black;
    --grey-color: #B6C4DE45;

    --cell-border: 0.05rem solid rgba(182,196,222,.7);
    --error-color: red;
    --toaster-icon-success: white;

    --deactive-token: #C0C0C0;
    --open-position-element-bg: transparent linear-gradient(190deg, #5155BC 0%, #B63B78 100%) 0% 0% no-repeat padding-box;
}
